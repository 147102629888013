/**
*
* Basic page needs
*
**/
@font-face {
  font-family: 'Roboto', sans-serif;
  src: url("../fonts/Roboto-Medium.ttf");
  src: url("../fonts/Roboto-Regular.ttf");
  src: url("../fonts/Roboto-MediumItalic.ttf");
  font-style: normal; }

@font-face {
  font-family: 'OpenSans', sans-serif;
  src: url("../fonts/OpenSans-Regular.ttf");
  src: url("../fonts/OpenSans-SemiBold.ttf");
  src: url("../fonts/OpenSans-Bold.ttf");
  font-style: normal; }

/*
*
* Vendor/ownCarousel
*
*/
/*
 *  Owl Carousel - Core
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1; }
  .owl-carousel .owl-stage {
    position: relative;
    -ms-touch-action: pan-Y;
    touch-action: manipulation;
    -moz-backface-visibility: hidden;
    /* fix firefox animation glitch */ }
  .owl-carousel .owl-stage:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0; }
  .owl-carousel .owl-stage-outer {
    position: relative;
    overflow: hidden;
    /* fix for flashing background */
    -webkit-transform: translate3d(0px, 0px, 0px); }
  .owl-carousel .owl-wrapper,
  .owl-carousel .owl-item {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0); }
  .owl-carousel .owl-item {
    position: relative;
    min-height: 1px;
    float: left;
    -webkit-backface-visibility: hidden;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none; }
  .owl-carousel .owl-item img {
    display: block;
    width: 100%; }
  .owl-carousel .owl-nav.disabled,
  .owl-carousel .owl-dots.disabled {
    display: none; }
  .owl-carousel .owl-nav .owl-prev,
  .owl-carousel .owl-nav .owl-next,
  .owl-carousel .owl-dot {
    cursor: pointer;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  .owl-carousel .owl-nav button.owl-prev,
  .owl-carousel .owl-nav button.owl-next,
  .owl-carousel button.owl-dot {
    background: none;
    color: inherit;
    border: none;
    padding: 0 !important;
    font: inherit; }
  .owl-carousel .owl-nav {
    display: flex;
    justify-content: flex-end;
    max-width: 1300px;
    margin: 20px auto 0; }
    .owl-carousel .owl-nav button.owl-prev,
    .owl-carousel .owl-nav button.owl-next {
      width: 30px;
      height: 30px;
      border: 1px solid #0E57B9;
      border-radius: 3px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center; }
      .owl-carousel .owl-nav button.owl-prev:hover,
      .owl-carousel .owl-nav button.owl-next:hover {
        background: #F3F3F3; }
      .owl-carousel .owl-nav button.owl-prev:focus,
      .owl-carousel .owl-nav button.owl-next:focus {
        outline: none; }
  .owl-carousel .owl-nav button.owl-next img {
    transform: rotate(180deg); }
  .owl-carousel.owl-loaded {
    display: block; }
  .owl-carousel.owl-loading {
    opacity: 0;
    display: block; }
  .owl-carousel.owl-hidden {
    opacity: 0; }
  .owl-carousel.owl-refresh .owl-item {
    visibility: hidden; }
  .owl-carousel.owl-drag .owl-item {
    touch-action: pan-y;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  .owl-carousel.owl-grab {
    cursor: move;
    cursor: grab; }
  .owl-carousel.owl-rtl {
    direction: rtl; }
  .owl-carousel.owl-rtl .owl-item {
    float: right; }

/* No Js */
.no-js .owl-carousel {
  display: block; }

/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
  animation-duration: 1000ms;
  animation-fill-mode: both; }

.owl-carousel .owl-animated-in {
  z-index: 0; }

.owl-carousel .owl-animated-out {
  z-index: 1; }

.owl-carousel .fadeOut {
  animation-name: fadeOut; }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

/*
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  transition: height 500ms ease-in-out; }

/*
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item {
  /**
			This is introduced due to a bug in IE11 where lazy loading combined with autoheight plugin causes a wrong
			calculation of the height of the owl-item that breaks page layouts
		 */ }
  .owl-carousel .owl-item .owl-lazy {
    opacity: 0;
    transition: opacity 400ms ease; }
  .owl-carousel .owl-item .owl-lazy[src^=""], .owl-carousel .owl-item .owl-lazy:not([src]) {
    max-height: 0; }
  .owl-carousel .owl-item img.owl-lazy {
    transform-style: preserve-3d; }

/*
 * 	Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000; }

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url("owl.video.play.png") no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: transform 100ms ease; }

.owl-carousel .owl-video-play-icon:hover {
  transform: scale(1.3, 1.3); }

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none; }

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 400ms ease; }

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%; }

/*
 * 	Default theme - Owl Carousel CSS File
 */
.owl-theme .owl-nav {
  margin-top: 10px;
  text-align: center;
  -webkit-tap-highlight-color: transparent; }
  .owl-theme .owl-nav [class*='owl-'] {
    color: #FFF;
    font-size: 14px;
    margin: 5px;
    padding: 4px 7px;
    background: #D6D6D6;
    display: inline-block;
    cursor: pointer;
    border-radius: 3px; }
    .owl-theme .owl-nav [class*='owl-']:hover {
      background: #869791;
      color: #FFF;
      text-decoration: none; }
  .owl-theme .owl-nav .disabled {
    opacity: 0.5;
    cursor: default; }

.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 10px; }

.owl-theme .owl-dots {
  text-align: center;
  -webkit-tap-highlight-color: transparent; }
  .owl-theme .owl-dots .owl-dot {
    display: inline-block;
    zoom: 1;
    *display: inline; }
    .owl-theme .owl-dots .owl-dot span {
      width: 10px;
      height: 10px;
      margin: 5px 7px;
      background: #D6D6D6;
      display: block;
      -webkit-backface-visibility: visible;
      transition: opacity 200ms ease;
      border-radius: 30px; }
    .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
      background: #869791; }

/*
*
* Adjustment Settings
*
*/
* {
  box-sizing: border-box; }

html,
body {
  margin: 0;
  padding: 0;
  font-family: "OpenSans", sans-serif; }

.container {
  width: 1360px;
  margin: 0 auto;
  padding: 0; }
  @media screen and (max-width: 1380px) {
    .container {
      width: 1000px; } }
  @media screen and (max-width: 1120px) {
    .container {
      width: 980px; } }
  @media screen and (max-width: 982px) {
    .container {
      width: 900px; } }
  @media screen and (max-width: 918px) {
    .container {
      width: 820px; } }
  @media screen and (max-width: 878px) {
    .container {
      width: 800px; } }
  @media screen and (max-width: 857px) {
    .container {
      width: 720px; } }
  @media screen and (max-width: 769px) {
    .container {
      width: 700px; } }
  @media screen and (max-width: 677px) {
    .container {
      width: 550px;
      padding: 0 20px; } }
  @media screen and (max-width: 644px) {
    .container {
      width: 500px; } }
  @media screen and (max-width: 451px) {
    .container {
      width: 400px; } }
  @media screen and (max-width: 590px) {
    .container {
      width: auto; } }
  @media screen and (max-width: 472px) {
    .container {
      width: 370px; } }

.container__flex {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  @media screen and (max-width: 1380px) {
    .container__flex {
      display: flex;
      justify-content: center;
      align-items: center; } }

@media screen and (max-width: 769px) {
  .container__mobile {
    justify-content: space-between; } }

@media screen and (max-width: 451px) {
  .container__mobile {
    display: flex;
    flex-flow: column;
    justify-content: center; } }

.section__title {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row wrap;
  padding: 30px 0; }
  @media screen and (max-width: 992px) {
    .section__title {
      padding: 0; } }
  @media screen and (max-width: 761px) {
    .section__title {
      flex-flow: column wrap;
      justify-content: center;
      align-items: center;
      padding: 30px 0 30px 0; } }
  @media screen and (max-width: 451px) {
    .section__title {
      padding: 0; } }

.section__title--text-main {
  font-family: 'OpenSans', sans-serif;
  font-weight: 600;
  text-align: center;
  color: #0E57B9;
  font-size: 36px;
  line-height: 49px; }
  @media screen and (max-width: 992px) {
    .section__title--text-main {
      padding-bottom: 0;
      margin-bottom: 0; } }
  @media screen and (max-width: 769px) {
    .section__title--text-main {
      margin-top: 0;
      padding: 0;
      font-size: 30px;
      line-height: 40px; } }
  @media screen and (max-width: 451px) {
    .section__title--text-main {
      font-size: 20px;
      line-height: 30px; } }

.section__title--text-descr {
  font-family: 'Roboto', sans-serif;
  font-size: 24px;
  line-height: 34px; }
  @media screen and (max-width: 992px) {
    .section__title--text-descr {
      text-align: center; } }
  @media screen and (max-width: 769px) {
    .section__title--text-descr {
      font-size: 20px;
      line-height: 30px; } }
  @media screen and (max-width: 761px) {
    .section__title--text-descr {
      width: 100%; } }
  @media screen and (max-width: 451px) {
    .section__title--text-descr {
      font-size: 18px;
      line-height: 20px;
      font-weight: normal; } }

.back-to-top {
  display: none;
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  margin: 0;
  padding: 15px;
  background: #ffffff;
  color: #c7c9ce;
  cursor: pointer;
  text-decoration: none;
  box-shadow: 3px 0 10px rgba(0, 0, 0, 0.5);
  z-index: 9;
  font-size: 35px;
  text-align: center;
  transform: rotate(-90deg); }
  @media screen and (max-width: 918px) {
    .back-to-top {
      display: none !important; } }

.overlay {
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  display: none;
  background: rgba(0, 0, 0, 0); }

.overlay--active {
  display: block; }

@media screen and (max-width: 992px) {
  .main__map > iframe {
    order: 2;
    width: 100%; } }

/*
*
* Header
*
*/
.header {
  margin: 0;
  padding: 0; }
  @media screen and (max-width: 769px) {
    .header {
      margin: 20px 0; } }

.header__top {
  width: 100%; }

.header__address {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-style: normal; }
  @media screen and (max-width: 769px) {
    .header__address {
      flex-flow: column wrap; } }

.header__address--link {
  display: flex; }
  .header__address--link :last-child {
    margin-right: 0; }
  @media screen and (max-width: 1380px) {
    .header__address--link {
      display: flex;
      padding-bottom: 0;
      flex-flow: column; } }
  @media screen and (max-width: 761px) {
    .header__address--link {
      padding: 10px 0; } }
  .header__address--link .social-icon {
    display: flex; }
    @media screen and (max-width: 769px) {
      .header__address--link .social-icon {
        justify-content: center; } }

.address__link {
  display: flex;
  align-items: center;
  font-weight: 600;
  color: #0E57B9;
  font-size: 18px;
  line-height: 25px;
  text-decoration: none;
  letter-spacing: .5px;
  margin-right: 10px;
  padding: 5px 10px;
  border-radius: 3px;
  transition: color .2s ease-in-out;
  transition: background .2s ease-in-out; }
  .address__link svg {
    color: #0E57B9;
    margin-right: 5px; }
  .address__link:hover {
    color: #1faae2; }
  .address__link:active {
    background: rgba(109, 110, 113, 0.1); }
  @media screen and (max-width: 992px) {
    .address__link {
      padding: 0;
      font-size: 14px;
      line-height: 18px; } }
  @media screen and (max-width: 769px) {
    .address__link {
      padding-bottom: 10px; } }
  @media screen and (max-width: 451px) {
    .address__link {
      justify-content: center; } }

.header__social--link {
  cursor: pointer;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row wrap;
  width: 30px;
  padding: 15px 0;
  margin-right: 20px; }
  .header__social--link:last-child {
    border: none;
    margin-right: 0; }

/*
*
* Navigation
*
*/
.main-navigation {
  border: none;
  outline: none;
  background: none;
  color: #0E57B9;
  cursor: pointer;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  position: relative;
  width: 350px;
  text-align: left; }
  .main-navigation:hover {
    color: #1faae2; }
  .main-navigation:active {
    color: rgba(109, 110, 113, 0.1); }
  @media screen and (max-width: 769px) {
    .main-navigation {
      width: 260px;
      font-size: 20px; } }
  @media screen and (max-width: 451px) {
    .main-navigation {
      text-align: center; } }

@media screen and (max-width: 761px) {
  .nav__wrap {
    background: #ffffff; } }

.nav__flex {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.nav__list {
  display: none;
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column wrap;
  position: absolute;
  background: #ffffff;
  width: 350px; }
  @media screen and (max-width: 769px) {
    .nav__list {
      width: 260px; } }
  .nav__list li {
    text-align: center;
    width: inherit;
    border-bottom: 1px solid rgba(0, 93, 121, 0.5); }
  .nav__list :last-child {
    border-bottom: none; }
  @media screen and (max-width: 761px) {
    .nav__list {
      flex-flow: column nowrap; } }

@media screen and (max-width: 761px) {
  .nav__item {
    width: 100%; } }

.nav__item--link {
  position: relative;
  display: block;
  text-decoration: none;
  color: #0E57B9;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: .5px;
  padding: 10px 10px;
  transition: color .3s ease-in-out; }
  .nav__item--link:hover {
    color: #1faae2; }
  .nav__item--link:active {
    color: rgba(109, 110, 113, 0.1); }
  @media screen and (max-width: 860px) {
    .nav__item--link {
      padding: 30px 10px; } }
  @media screen and (max-width: 769px) {
    .nav__item--link {
      text-align: center;
      line-height: 22px;
      font-weight: normal;
      padding: 30px 15px; } }
  @media screen and (max-width: 761px) {
    .nav__item--link:hover {
      color: #0E57B9; }
    .nav__item--link:active {
      color: #011632; } }

.show--main-nav {
  display: flex;
  z-index: 9999999999; }

/*
*
* Hero
*
*/
.hero {
  width: 100%;
  height: 600px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-end;
  position: relative; }
  @media screen and (max-width: 992px) {
    .hero {
      height: 450px; } }
  @media screen and (max-width: 769px) {
    .hero {
      height: 350px; } }
  @media screen and (max-width: 761px) {
    .hero {
      height: 250px; } }

.hero__content {
  display: flex;
  align-items: flex-start;
  flex-flow: column wrap;
  position: absolute;
  top: 40px;
  color: #fff;
  margin: 0; }
  @media screen and (max-width: 992px) {
    .hero__content {
      align-items: flex-start;
      top: 0; } }
  @media screen and (max-width: 761px) {
    .hero__content {
      justify-content: center;
      align-items: center; } }

.hero__title--top {
  font-size: 48px;
  line-height: 65px;
  font-weight: 700;
  margin-bottom: 40px;
  position: relative;
  z-index: 999; }
  @media screen and (max-width: 992px) {
    .hero__title--top {
      margin-bottom: 20px; } }
  @media screen and (max-width: 769px) {
    .hero__title--top {
      top: 0; } }
  @media screen and (max-width: 761px) {
    .hero__title--top {
      display: flex;
      font-size: 40px;
      line-height: 41px; } }
  @media screen and (max-width: 518px) {
    .hero__title--top {
      text-align: center; } }
  @media screen and (max-width: 451px) {
    .hero__title--top {
      font-size: 20px;
      line-height: 40px;
      margin-bottom: 80px; } }

.hero__title--bottom {
  font-family: 'Roboto', sans-serif;
  font-style: italic;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px; }
  @media screen and (max-width: 769px) {
    .hero__title--bottom {
      position: relative;
      bottom: -40px; } }
  @media screen and (max-width: 761px) {
    .hero__title--bottom {
      display: flex;
      font-size: 18px;
      line-height: 20px;
      padding-top: 20px;
      z-index: 999; } }
  @media screen and (max-width: 518px) {
    .hero__title--bottom {
      text-align: center; } }
  @media screen and (max-width: 451px) {
    .hero__title--bottom {
      padding-top: 0; } }

.hero__gallery {
  text-align: right; }
  .hero__gallery .owl-carousel-main {
    position: relative; }
    .hero__gallery .owl-carousel-main .owl-nav {
      display: flex;
      justify-content: space-between;
      position: absolute;
      width: 100%;
      height: 100%;
      max-width: 100vw;
      top: -15px;
      left: 0; }
      .hero__gallery .owl-carousel-main .owl-nav button.owl-prev,
      .hero__gallery .owl-carousel-main .owl-nav button.owl-next {
        width: 100px;
        height: 100%;
        border: none;
        background: transparent;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: background .1s;
        transform: rotate(180deg); }
        .hero__gallery .owl-carousel-main .owl-nav button.owl-prev img,
        .hero__gallery .owl-carousel-main .owl-nav button.owl-next img {
          opacity: 0.3;
          width: 100%; }
        .hero__gallery .owl-carousel-main .owl-nav button.owl-prev:hover img,
        .hero__gallery .owl-carousel-main .owl-nav button.owl-next:hover img {
          opacity: 0.7; }
        .hero__gallery .owl-carousel-main .owl-nav button.owl-prev:focus,
        .hero__gallery .owl-carousel-main .owl-nav button.owl-next:focus {
          outline: none; }

.hero__gallery--track {
  width: 100%;
  height: 650px;
  flex-flow: row nowrap;
  overflow: hidden;
  margin-bottom: 15px; }
  @media screen and (max-width: 992px) {
    .hero__gallery--track {
      height: 450px; } }
  @media screen and (max-width: 769px) {
    .hero__gallery--track {
      height: 350px; } }
  @media screen and (max-width: 761px) {
    .hero__gallery--track {
      height: 250px; } }

.hero__gallery--img {
  padding: 0;
  margin: 0;
  position: relative;
  width: 100%;
  height: 650px; }
  @media screen and (max-width: 992px) {
    .hero__gallery--img {
      height: 450px; } }
  @media screen and (max-width: 769px) {
    .hero__gallery--img {
      height: 350px; } }
  @media screen and (max-width: 761px) {
    .hero__gallery--img {
      height: 250px; } }

.hero__gallery--next--btn,
.hero__gallery--prev--btn {
  display: inline-block;
  background: none;
  outline: none;
  border: 1px solid #011632;
  border-radius: 1px;
  font-size: 18px;
  color: #011632;
  cursor: pointer; }

/*
*
* Workshop
*
*/
@media screen and (max-width: 769px) {
  .workshop .section__title {
    margin-bottom: 20px; } }

@media screen and (max-width: 769px) {
  .workshop .section__img {
    margin-bottom: 20px; } }

.workshop .section__img img {
  margin: 0 auto;
  display: block;
  width: 100%;
  max-width: 1264px; }

.workshop .section__slider {
  margin: 0 auto;
  display: block;
  width: 100%;
  max-width: 1264px; }
  .workshop .section__slider .owl-carousel-yoga .item {
    height: 370px;
    overflow: hidden;
    border-radius: 5px;
    position: relative; }
    .workshop .section__slider .owl-carousel-yoga .item img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover; }

/*
*
* About
*
*/
@media screen and (max-width: 918px) {
  .about {
    padding-top: 30px; } }

.about__list {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  list-style: none;
  color: #0E57B9;
  margin: 0;
  padding: 0; }
  .about__list img {
    padding-right: 15px; }
  @media screen and (max-width: 982px) {
    .about__list {
      flex-flow: column wrap; } }

.about__item {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 50px 50px 0;
  width: 50%; }
  .about__item :last-child {
    padding: 0 0 50px 0; }
  @media screen and (max-width: 1120px) {
    .about__item {
      padding: 0 25px 25px 25px; } }
  @media screen and (max-width: 982px) {
    .about__item {
      width: 100%; } }
  @media screen and (max-width: 761px) {
    .about__item {
      flex-flow: column wrap;
      justify-content: center;
      align-items: center;
      padding: 0 15px 20px 15px; }
      .about__item :last-child {
        padding: 0 0 10px 0; } }

.about__item-descr {
  display: flex;
  flex-flow: row wrap; }
  @media screen and (max-width: 761px) {
    .about__item-descr {
      flex-flow: column wrap;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
      padding-bottom: 0; } }

.about__item--title {
  font-size: 26px;
  line-height: 26px;
  padding-bottom: 15px; }

.about__item--descr {
  font-size: 16px;
  line-height: 24px; }

/*
*
* Appartments
*
*/
.wedo__list {
  list-style: none;
  color: #4F4F4F;
  margin: 0 auto;
  padding: 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 20px; }
  @media screen and (max-width: 982px) {
    .wedo__list {
      flex-flow: column wrap; } }
  @media screen and (max-width: 769px) {
    .wedo__list {
      margin-bottom: 20px; } }

.wedo__item {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-flow: row nowrap;
  width: 45%;
  padding: 0;
  margin: 30px 20px; }
  @media screen and (max-width: 1120px) {
    .wedo__item {
      padding: 0 35px 80px 35px; } }
  @media screen and (max-width: 992px) {
    .wedo__item {
      width: 100%;
      padding: 0;
      margin: 10px 0; } }
  @media screen and (max-width: 982px) {
    .wedo__item {
      justify-content: space-around; } }
  @media screen and (max-width: 518px) {
    .wedo__item {
      flex-flow: column wrap;
      justify-content: center;
      align-items: center; } }

.wedo__item--descr--about {
  font-size: 18px;
  line-height: 25px; }

.rooms__item {
  width: 100%;
  margin: 40px auto;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-flow: row; }
  @media screen and (max-width: 769px) {
    .rooms__item {
      margin: 10px 0;
      flex-flow: column; } }
  .rooms__item--slider {
    margin: 0;
    display: block;
    width: 50%; }
    @media screen and (max-width: 761px) {
      .rooms__item--slider {
        width: 100%; } }
    .rooms__item--slider .owl-carousel-corpus .item {
      height: 260px;
      overflow: hidden;
      border-radius: 5px;
      position: relative; }
      .rooms__item--slider .owl-carousel-corpus .item img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover; }

.rooms__item--img--wrap {
  width: 50%; }
  @media screen and (max-width: 769px) {
    .rooms__item--img--wrap {
      order: 2;
      width: 100%; } }

.rooms__item--img {
  display: flex;
  width: calc(50% - 10px);
  height: 260px; }
  @media screen and (max-width: 761px) {
    .rooms__item--img {
      display: block;
      height: auto; } }
  .rooms__item--img > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 0 0;
    margin: 0;
    padding-right: 20px;
    border-radius: 5px; }
    .rooms__item--img > img:first-child {
      margin: 0 20px 0 0;
      padding: 0; }
    .rooms__item--img > img:last-child {
      padding: 0; }
    @media screen and (max-width: 769px) {
      .rooms__item--img > img {
        padding-right: 0; } }
  @media screen and (max-width: 761px) {
    .rooms__item--img {
      width: 100%;
      flex-flow: column; } }

.rooms__item--descr--wrap {
  width: 45%; }
  .rooms__item--descr--wrap > span {
    display: block; }
  @media screen and (max-width: 769px) {
    .rooms__item--descr--wrap {
      order: 1;
      width: 100%;
      margin-bottom: 20px; } }

.rooms__item--title {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  color: #0E57B9;
  font-size: 24px;
  line-height: 34px;
  margin-bottom: 20px; }
  @media screen and (max-width: 992px) {
    .rooms__item--title {
      margin: 0; } }

.rooms__item--descr {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  color: #333333;
  margin-bottom: 5px; }

.rooms__item--text {
  font-family: 'Roboto', sans-serif;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  color: #4F4F4F; }

.views--wrap {
  display: flex;
  justify-content: space-between; }

.views__item--img {
  width: 100%;
  height: 250px;
  margin: 0 20px 0 0; }
  .views__item--img:last-child {
    margin: 0; }
  .views__item--img > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 0 0;
    margin: 0;
    padding: 0;
    border-radius: 5px; }
    @media screen and (max-width: 761px) {
      .views__item--img > img {
        padding: 0;
        margin: 5px 0; }
        .views__item--img > img:last-child {
          margin: 5px 0; } }
  @media screen and (max-width: 769px) {
    .views__item--img {
      margin-top: 30px;
      height: 200px; } }
  @media screen and (max-width: 761px) {
    .views__item--img {
      width: 100%;
      flex-flow: column;
      height: auto; } }

/*
*
* Price
*
*/
.price-new__title {
  color: #0E57B9;
  text-align: center;
  font-size: 36px;
  margin-bottom: 15px; }

.price-new__sub-title {
  color: #333333;
  text-align: center;
  font-size: 20px;
  margin-bottom: 15px; }

.price-new__table {
  margin: 30px 0 20px 0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  width: 100%;
  overflow-x: auto; }
  .price-new__table .table-price-list {
    border-collapse: collapse;
    border: 1px solid #12488F;
    width: 100%; }
    .price-new__table .table-price-list thead tr th {
      border: 1px solid #12488F;
      background: #12488F;
      color: #fff;
      font-size: 18px;
      padding: 15px 15px 8px;
      text-align: left; }
      .price-new__table .table-price-list thead tr th:nth-child(3) {
        padding-left: 140px; }
    .price-new__table .table-price-list thead tr:last-child th {
      padding: 0 15px 10px;
      font-size: 16px; }
      .price-new__table .table-price-list thead tr:last-child th:nth-child(3) {
        padding-left: 140px; }
    .price-new__table .table-price-list tbody tr td {
      border: 1px solid #12488F;
      padding: 10px;
      font-size: 16px;
      font-weight: 500;
      text-align: center;
      vertical-align: middle; }
      .price-new__table .table-price-list tbody tr td .note {
        color: #EB5757; }
    .price-new__table .table-price-list tbody tr:nth-child(2n) td:first-child {
      color: #0E57B9;
      font-weight: 700;
      position: sticky;
      left: 0; }
    .price-new__table .table-price-list tbody tr:nth-child(2n) td:nth-child(2n) {
      font-size: 18px;
      font-weight: 700; }
    .price-new__table .table-price-list tbody tr.price-list__early td {
      background: #E7F7EF; }
    .price-new__table .table-price-list tbody tr.price-list__normal td {
      background: #E6EEF9; }
    .price-new__table .table-price-list tbody tr.price-list__hot td {
      background: #FAEFCC; }
    .price-new__table .table-price-list tbody tr.price-list__velvet td {
      background: #FDEBDC; }
    .price-new__table .table-price-list tbody tr.price-list__heading td {
      font-size: 14px;
      color: #333333;
      text-align: left; }

.price-new__additional-info {
  margin: 20px 0;
  padding: 20px;
  background: #E7EEF8; }

.price-new__info {
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  color: #333333;
  margin-bottom: 30px; }
  .price-new__info .note {
    color: #EB5757; }
  .price-new__info p {
    margin: 0; }
  .price-new__info h4 {
    margin: 0;
    font-size: 20px;
    line-height: 28px; }
  .price-new__info ol {
    margin: 5px 0;
    padding-left: 20px; }
  .price-new__info_modal-link {
    color: #eb5757;
    text-decoration: underline; }
    .price-new__info_modal-link:hover {
      text-decoration: none; }

/*
*
* Location
*
*/
@media screen and (max-width: 769px) {
  .location {
    padding: 0; } }

.wedo__item--descr--title {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 28px;
  line-height: 33px;
  color: #EB5757;
  margin: 0 0 20px 0; }

.wedo__item--descr--about > p {
  margin: 20px 0; }

.map {
  width: 100%;
  height: 450px; }

/*
*
* Footer
*
*/
.footer {
  background: url("../img/footer.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  flex-flow: column;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
  padding: 0 0 0 0;
  position: relative;
  top: 50px;
  transform: translateY(-50px);
  font-family: 'OpenSans', sans-serif; }
  @media screen and (max-width: 761px) {
    .footer {
      height: 650px; } }
  .footer .footer-opacity {
    display: block;
    position: absolute;
    background: linear-gradient(white, transparent);
    width: 100%;
    height: 150px;
    top: 0;
    left: 0; }
    @media screen and (max-width: 761px) {
      .footer .footer-opacity {
        display: none; } }

.footer__title {
  text-align: center;
  font-weight: 700;
  color: #F7F7F7;
  font-size: 48px;
  line-height: 78px;
  letter-spacing: 10px;
  text-transform: uppercase;
  margin-bottom: 100px; }
  @media screen and (max-width: 769px) {
    .footer__title {
      color: #0E57B9;
      position: relative;
      top: -100px; } }
  @media screen and (max-width: 761px) {
    .footer__title {
      top: -200px; } }

.footer__content {
  width: 100%;
  background: linear-gradient(180deg, rgba(0, 93, 121, 0.4) -70.53%, rgba(1, 73, 96, 0.8) 70.71%);
  position: absolute;
  bottom: 0; }
  @media screen and (max-width: 761px) {
    .footer__content {
      height: 100%;
      padding-top: 170px; } }

.footer__wrap {
  padding: 30px 0 10px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-flow: row;
  font-style: normal;
  color: #ffffff; }
  @media screen and (max-width: 769px) {
    .footer__wrap {
      flex-flow: row wrap;
      align-items: flex-start; } }
  @media screen and (max-width: 761px) {
    .footer__wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-flow: column wrap; } }

.footer__section {
  display: flex;
  flex-flow: row wrap;
  width: 23%;
  margin: 0; }
  .footer__section > h3 {
    margin: 0; }
  @media screen and (max-width: 769px) {
    .footer__section {
      width: 50%; } }
  @media screen and (max-width: 761px) {
    .footer__section {
      width: 100%;
      display: block;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px; } }

.footer--location {
  display: block;
  width: 40%; }
  @media screen and (max-width: 761px) {
    .footer--location {
      width: 100%; } }
  .footer--location > h3 {
    font-size: 18px;
    font-weight: 700;
    line-height: 25px; }
  .footer--location > p {
    font-size: 18px;
    font-weight: normal;
    line-height: 25px; }
  @media screen and (max-width: 761px) {
    .footer--location {
      text-align: center; } }

.footer--phones {
  display: flex;
  order: 2;
  flex-flow: column; }
  .footer--phones > a {
    text-decoration: none;
    color: #ffffff;
    font-size: 14px;
    font-weight: 700;
    line-height: 19px; }

.footer--registr {
  flex-flow: column;
  order: 3;
  font-size: 18px;
  font-weight: 600;
  line-height: 25px; }
  .footer--registr > h3 {
    margin-bottom: 15px; }
  .footer--registr > a {
    display: block;
    font-size: 14px;
    font-weight: 700;
    line-height: 19px;
    text-decoration: none;
    color: #ffffff; }
    .footer--registr > a:last-child {
      margin: 0;
      font-size: 18px;
      font-weight: normal;
      line-height: 25px;
      color: #ffffff;
      text-decoration: none;
      cursor: pointer; }
      .footer--registr > a:last-child:hover {
        color: #1faae2; }
      .footer--registr > a:last-child > svg {
        margin-right: 5px; }
  @media screen and (max-width: 992px) {
    .footer--registr {
      width: 30%; } }
  @media screen and (max-width: 769px) {
    .footer--registr {
      width: 50%; } }
  @media screen and (max-width: 761px) {
    .footer--registr {
      width: 100%;
      text-align: center; } }

.footer__social {
  order: 4;
  flex-flow: column;
  align-items: center; }
  .footer__social > p {
    text-align: center;
    margin: 0;
    font-size: 18px;
    font-weight: normal;
    line-height: 25px; }
  @media screen and (max-width: 992px) {
    .footer__social {
      width: 18%; } }
  @media screen and (max-width: 769px) {
    .footer__social {
      width: 20%; } }
  @media screen and (max-width: 761px) {
    .footer__social {
      width: 100%; } }

.social__wrap {
  display: flex; }
  @media screen and (max-width: 761px) {
    .social__wrap {
      display: flex;
      justify-content: center;
      align-items: center; } }

.footer__social--link {
  cursor: pointer;
  color: #ffffff;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row wrap;
  width: 66px;
  padding: 15px 0; }
  .footer__social--link:last-child {
    border: none; }

#tl-anchor {
  margin: 30px auto;
  max-width: 1360px; }

#tl-booking-form {
  margin: 0 auto;
  max-width: 1360px;
  padding: 0 10px; }

#block-search {
  background: rgba(14, 87, 185, 0.8);
  padding: 0 29px; }

.tl-container {
  width: 100%;
  max-width: 1360px;
  margin: 0 auto; }
